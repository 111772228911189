import { APP_INITIALIZER, inject } from '@angular/core';
import { TaxDto, TaxEntityName } from '@nest/domain/entities-metadata/dtos';
import { TaxDataAccessService } from '@nest/domain/finance/taxes/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

export function provideTaxesEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				let taxDataAccess = inject(TaxDataAccessService);

				return () => {
					entityMetadata.registerEntity({ entityName: TaxEntityName, dataAccess: taxDataAccess, defaultElements: fieldsToElements(autoPathsFor<TaxDto>()(['code'])) });
				};
			}
		}
	];
}
