import { Injectable, inject } from '@angular/core';
import { AuthService } from '@nest/common/frontend/core';
import { PartyDataAccessService } from '@nest/domain/parties/frontend/data-access';
import { derivedAsync } from 'ngxtension/derived-async';

@Injectable({
	providedIn: 'root'
})
export class ApplicationService {
	auth = inject(AuthService);
	partiesDataAccess = inject(PartyDataAccessService);

	organization = derivedAsync(() => {
		const organizationId = this.auth.organizationId();
		return organizationId ? this.partiesDataAccess.getById(organizationId) : null;
	});
}
