import { defineProcedure, defineRouter } from '@nest/common/rpc/shared';
import { InboundDeliveryOrderDocumentTypeDto, InboundDeliveryOrderDto, InboundQueueDto } from '@nest/domain/entities-metadata/dtos';
import { type } from '@nest/utils/shared';
import { z } from 'zod';

export const wmsInboundRouter = defineRouter({
	inboundQueues: {
		getAllForMe: defineProcedure({
			input: z.object({
				filter: z.any().optional()
			}),
			output: type<InboundQueueDto[]>()
		})
	},
	inboundDeliveryOrderDocumentTypes: {
		getAllForMe: defineProcedure({
			input: z.object({
				options: z.any().optional(),
				filter: z.any().optional()
			}),
			output: type<InboundDeliveryOrderDocumentTypeDto[]>()
		})
	},
	inboundDeliveryOrders: {
		countByDocumentType: defineProcedure({
			input: z.object({
				documentTypeId: z.number()
			}),
			output: type<number>()
		}),
		countByQueue: defineProcedure({
			input: z.object({
				queueId: z.number()
			}),
			output: type<number>()
		}),
		getAllByQueue: defineProcedure({
			input: z.object({
				queueId: z.number(),
				query: z.any().optional()
			}),
			output: type<InboundDeliveryOrderDto[]>()
		})
	},
	inboundDeliveryOrderProcess: {
		start: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		leave: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		startLine: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		leaveLine: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		cancelLineQuantity: defineProcedure({
			input: z.object({
				id: z.coerce.number(),
				quantity: z.coerce.number()
			}),
			output: type<void>()
		}),
		scanStockItem: defineProcedure({
			input: z.object({
				id: z.coerce.number(),
				code: z.string()
			}),
			output: type<any>()
		}),
		scanStockItemInDocument: defineProcedure({
			input: z.object({
				id: z.coerce.number(),
				code: z.string()
			}),
			output: type<any>()
		}),
		scanHandlingUnit: defineProcedure({
			input: z.object({
				id: z.coerce.number(),
				code: z.string()
			}),
			output: type<any>()
		}),
		receive: defineProcedure({
			input: z.object({
				inboundDeliveryOrderLineId: z.coerce.number(),
				stockItemId: z.coerce.number(),
				destinationLocationId: z.coerce.number(),
				destinationHandlingUnitId: z.coerce.number().optional(),
				quantity: z.coerce.number(),
				batch: z.coerce.number().optional(),
				originCountry: z.string().optional()
			}),
			output: type<void>()
		}),
		confirm: defineProcedure({
			input: z.object({
				inboundDeliveryOrderId: z.coerce.number(),
				confirmWithErrors: z.boolean()
			}),
			output: type<void>()
		})
	}
});
