import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, provideRouter, withComponentInputBinding } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
	AuthService,
	EnvironmentRef,
	GlobalErrorHandler,
	IdentityPreferencesService,
	JwtService,
	LocaleService,
	httpJwtInterceptor,
	provideApplicationName
} from '@nest/common/frontend/core';
import { provideActivitiesEntities } from '@nest/domain/activities/frontend/entity-presentation';
import { provideDeliveryEntities } from '@nest/domain/delivery/packages/frontend/entity-presentation';
import { provideTemplatesEntities } from '@nest/domain/documents/templates/frontend/entity-presentation';
import { provideEdiInboundOrdersEntities } from '@nest/domain/edi/sale-orders/frontend/entity-presentation';
import { provideInboundDocumentsEntities } from '@nest/domain/erp/inbound-documents/frontend/entity-presentation';
import { provideOutboundDocumentsEntities } from '@nest/domain/erp/outbound-documents/frontend/entity-presentation';
import { providePurchaseOrdersEntities } from '@nest/domain/erp/purchase-orders/frontend/entity-presentation';
import { provideSaleOrderEntities } from '@nest/domain/erp/sale-orders/frontend/entity-presentation';
import { provideTaxesEntities } from '@nest/domain/finance/taxes/frontend/entity-presentation';
import { provideItemsEntities } from '@nest/domain/items/frontend/entity-presentation';
import { providePartyEntities } from '@nest/domain/parties/frontend/entity-presentation';
import { providePricelistsEntities } from '@nest/domain/pricelists/frontend/entity-presentation';
import { provideShipmentRouteEntities, provideShipmentVehicleEntities } from '@nest/domain/shipment/plan/frontend/entity-presentation';
import { provideSuppliersPricelistsEntities } from '@nest/domain/suppliers-pricelists/frontend/entity-presentation';
import { provideWmsCoreEntities } from '@nest/domain/wms/core/frontend/entity-presentation';
import { provideHandlingUnitsEntities } from '@nest/domain/wms/handling-units/frontend/entity-presentation';
import { provideInboundEntities } from '@nest/domain/wms/inbound/frontend/entity-presentation';
import { provideOutboundEntities } from '@nest/domain/wms/outbound/frontend/entity-presentation';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
import { routes } from './routes';

if (environment.env !== 'development') {
	enableProdMode();
}

export const appConfig: ApplicationConfig = {
	providers: [
		provideApplicationName('desktop'),

		provideSaleOrderEntities(),
		providePartyEntities(),
		provideTaxesEntities(),
		provideActivitiesEntities(),
		provideItemsEntities(),
		providePurchaseOrdersEntities(),
		provideInboundDocumentsEntities(),
		provideTemplatesEntities(),
		provideOutboundDocumentsEntities(),
		provideEdiInboundOrdersEntities(),
		providePricelistsEntities(),
		provideSuppliersPricelistsEntities(),
		provideWmsCoreEntities(),
		provideHandlingUnitsEntities(),
		provideDeliveryEntities(),
		provideOutboundEntities(),
		provideInboundEntities(),
		provideShipmentVehicleEntities(),
		provideShipmentRouteEntities(),

		// provideExperimentalZonelessChangeDetection(),
		importProvidersFrom(BrowserModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.env !== 'development' })),
		provideRouter(routes, withComponentInputBinding()),
		provideHttpClient(withInterceptors([httpJwtInterceptor])),
		{
			provide: APP_INITIALIZER,
			useFactory: (srv: AuthService) => () => srv.startup(),
			deps: [AuthService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (srv: IdentityPreferencesService) => () => srv.refreshMyApplicationPreferences(),
			deps: [IdentityPreferencesService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (srv: LocaleService) => () => srv.startup(),
			deps: [LocaleService],
			multi: true
		},
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		{
			provide: Sentry.TraceService,
			deps: [Router]
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true
		},
		JwtService,
		{ provide: EnvironmentRef, useValue: environment }
	]
};
