/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { InboundDeliveryOrderDocumentTypeDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class InboundDeliveryOrderDocumentTypeDataAccessService {
	private api = inject(ApiService);

	// GET @ outbound/delivery/orders/document-types/
	getAll(query: EntityQueryDto<InboundDeliveryOrderDocumentTypeDto>) {
		return this.api.post<InboundDeliveryOrderDocumentTypeDto[]>(`InboundDeliveryOrderDocumentType.getAll`, { body: { data: query } });
	}

	// GET @ outbound/delivery/orders/document-types/
	getAllForMe(query: EntityQueryDto<InboundDeliveryOrderDocumentTypeDto>) {
		return this.api.post<InboundDeliveryOrderDocumentTypeDto[]>(`inboundDeliveryOrderDocumentTypes.getAllForMe`, { body: { data: query } });
	}

	// GET @ outbound/delivery/orders/document-types/:id
	getById(id: number, query: FindOptionsDto<InboundDeliveryOrderDocumentTypeDto>) {
		return this.api.post<InboundDeliveryOrderDocumentTypeDto>(`InboundDeliveryOrderDocumentType.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<InboundDeliveryOrderDocumentTypeDto>) {
		return this.api.post<number>(`InboundDeliveryOrderDocumentType.count`, { body: { data: query } });
	}
}
