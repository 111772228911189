import { APP_INITIALIZER, inject } from '@angular/core';
import {
	InboundDeliveryOrderDocumentTypeDto,
	InboundDeliveryOrderDocumentTypeEntityName,
	InboundDeliveryOrderDto,
	InboundDeliveryOrderEntityName,
	InboundDeliveryOrderLineDto,
	InboundDeliveryOrderLineEntityName,
	InboundQueueDto,
	InboundQueueEntityName
} from '@nest/domain/entities-metadata/dtos';
import {
	InboundDeliveryOrderDataAccessService,
	InboundDeliveryOrderDocumentTypeDataAccessService,
	InboundDeliveryOrderLineDataAccessService,
	InboundQueueDataAccessService
} from '@nest/domain/wms/inbound/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

export function provideInboundEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				let inboundDeliveryOrderDocumentTypeDataAccess = inject(InboundDeliveryOrderDocumentTypeDataAccessService);
				let inboundDeliveryOrderLineDataAccess = inject(InboundDeliveryOrderLineDataAccessService);
				let inboundDeliveryOrderDataAccess = inject(InboundDeliveryOrderDataAccessService);
				let inboundQueueDataAccess = inject(InboundQueueDataAccessService);

				return () => {
					entityMetadata.registerEntity({
						entityName: InboundDeliveryOrderDocumentTypeEntityName,
						dataAccess: inboundDeliveryOrderDocumentTypeDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<InboundDeliveryOrderDocumentTypeDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: InboundDeliveryOrderLineEntityName,
						dataAccess: inboundDeliveryOrderLineDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<InboundDeliveryOrderLineDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: InboundDeliveryOrderEntityName,
						dataAccess: inboundDeliveryOrderDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<InboundDeliveryOrderDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: InboundQueueEntityName,
						dataAccess: inboundQueueDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<InboundQueueDto>()(['id']))
					});
				};
			}
		}
	];
}
