/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService, injectRpcClient } from '@nest/common/frontend/core';
import { InboundDeliveryOrderDto } from '@nest/domain/entities-metadata/dtos';
import { wmsInboundRouter } from '@nest/domain/wms-inbound/shared';

@Injectable({
	providedIn: 'root'
})
export class InboundDeliveryOrderDataAccessService {
	private api = inject(ApiService);
	private client = injectRpcClient(wmsInboundRouter);

	// GET @ inbound/delivery/orders/
	getAll(query: EntityQueryDto<InboundDeliveryOrderDto>) {
		return this.api.post<InboundDeliveryOrderDto[]>(`inboundDeliveryOrders.getAll`, { body: { data: query } });
	}

	// GET @ inbound/delivery/orders/document-types/:documentTypeId/count
	countByDocumentType(id: number) {
		return this.client.inboundDeliveryOrders.countByDocumentType({ documentTypeId: id });
	}

	// GET @ inbound/delivery/orders/count
	count(query: EntityQueryDto<InboundDeliveryOrderDto>) {
		return this.api.post<number>(`inboundDeliveryOrders.count`, { body: { data: query } });
	}

	// GET @ inbound/delivery/orders/queues/:queueId
	getAllByQueue(queueId: number, query: EntityQueryDto<InboundDeliveryOrderDto>) {
		return this.client.inboundDeliveryOrders.getAllByQueue({ queueId, query });
	}

	// GET @ inbound/delivery/orders/queues/:queueId/count
	countByQueue(queueId: number) {
		return this.client.inboundDeliveryOrders.countByQueue({ queueId });
	}

	// GET @ inbound/delivery/orders/:id
	getById(id: number, query: FindOptionsDto<InboundDeliveryOrderDto>) {
		return this.api.post<InboundDeliveryOrderDto>(`inboundDeliveryOrders.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	// POST @ inbound/delivery/orders/:id?
	save(queueId: number, body: InboundDeliveryOrderDto) {
		return this.api.post<void>(`inbound/delivery/orders/:id?`, { body });
	}

	// POST @ inbound/delivery/orders/:id/cancel
	cancel(id: number) {
		return this.api.post<void>(`inbound/delivery/orders/${id}/cancel`, {});
	}
}
