/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { ApiService } from '@nest/common/frontend/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { InboundDeliveryOrderLineDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class InboundDeliveryOrderLineDataAccessService {
	private api = inject(ApiService);

	// GET @ inbound/delivery/orders/lines/
	getAll(query: EntityQueryDto<InboundDeliveryOrderLineDto>) {
		return this.api.post<InboundDeliveryOrderLineDto[]>(`inboundDeliveryOrderLines.getAll`, { body: { data: query } });
	}

	// GET @ inbound/delivery/orders/lines/:id
	getById(id: number, query: FindOptionsDto<InboundDeliveryOrderLineDto>) {
		return this.api.post<InboundDeliveryOrderLineDto>(`inboundDeliveryOrderLines.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<InboundDeliveryOrderLineDto>) {
		return this.api.post<number>(`inboundDeliveryOrderLines.count`, { body: { data: query } });
	}
}
