/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { ApiService } from '@nest/common/frontend/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { DeliveryItemDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class DeliveryItemDataAccessService {
	private api = inject(ApiService);

	// GET @ delivery/items/
	getAll(query: EntityQueryDto<DeliveryItemDto>) {
		return this.api.get<DeliveryItemDto[]>(`delivery/items/`, query);
	}

	// GET @ delivery/items/:id
	getById(id: number, query: FindOptionsDto<DeliveryItemDto>) {
		return this.api.get<DeliveryItemDto>(`delivery/items/${id}`, query);
	}
}
