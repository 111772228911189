import { APP_INITIALIZER, inject } from '@angular/core';
import { SupplierPricelistDto, SupplierPricelistEntityName, SupplierPricelistItemDto, SupplierPricelistItemEntityName } from '@nest/domain/entities-metadata/dtos';
import { SupplierPricelistDataAccessService, SupplierPricelistItemDataAccessService } from '@nest/domain/suppliers-pricelists/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

const supplierPricelistPaths = autoPathsFor<SupplierPricelistDto>();
const supplierPricelistItemPaths = autoPathsFor<SupplierPricelistItemDto>();

export const SupplierPricelistDefaultElements = fieldsToElements(supplierPricelistPaths(['name', 'code', 'currency', 'supplier.name']));
export const SupplierPricelistItemDefaultElements = fieldsToElements(
	supplierPricelistItemPaths(['id', 'product.code', 'product.name', 'price', 'validFrom', 'validTo', 'priceType'])
);

export function provideSuppliersPricelistsEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				let supplierPricelistItemDataAccess = inject(SupplierPricelistItemDataAccessService);
				let supplierPricelistDataAccess = inject(SupplierPricelistDataAccessService);

				return () => {
					entityMetadata.registerEntity({
						entityName: SupplierPricelistItemEntityName,
						dataAccess: supplierPricelistItemDataAccess,
						defaultElements: SupplierPricelistItemDefaultElements
					});

					entityMetadata.registerEntity({
						entityName: SupplierPricelistEntityName,
						dataAccess: supplierPricelistDataAccess,
						defaultElements: SupplierPricelistDefaultElements
					});
				};
			}
		}
	];
}
