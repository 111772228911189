/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService, injectRpcClient } from '@nest/common/frontend/core';
import { InboundQueueDto } from '@nest/domain/entities-metadata/dtos';
import { wmsInboundRouter } from '@nest/domain/wms-inbound/shared';

@Injectable({
	providedIn: 'root'
})
export class InboundQueueDataAccessService {
	private api = inject(ApiService);
	private client = injectRpcClient(wmsInboundRouter);

	// GET @ inbound/queues/
	getAll(query: EntityQueryDto<InboundQueueDto>) {
		return this.api.post<InboundQueueDto[]>(`inboundQueues.getAll`, { body: { data: query } });
	}

	// GET @ inbound/queues/for-user
	getAllForUser(query: EntityQueryDto<InboundQueueDto>) {
		return this.client.inboundQueues.getAllForMe(query);
	}

	// GET @ inbound/queues/:id
	getById(id: number, query?: FindOptionsDto<InboundQueueDto>) {
		return this.api.post<InboundQueueDto>(`inboundQueues.getById`, { body: { data: { id, ...query } } });
	}

	// POST @ inbound/queues/:id?
	save(id: number, body: InboundQueueDto) {
		return this.api.post<InboundQueueDto>(`inbound/queues/${id}?`, { body });
	}

	// DELETE @ inbound/queues/:id
	delete(id: number) {
		return this.api.delete<void>(`inbound/queues/${id}`);
	}
}
