/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { HandlingUnitDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class HandlingUnitDataAccessService {
	private api = inject(ApiService);

	// GET @ handling-units/
	getAll(query: EntityQueryDto<HandlingUnitDto>) {
		return this.api.post<HandlingUnitDto[]>(`handlingUnits.getAll`, { body: { data: query } });
	}

	// GET @ handling-units/:id
	getById(id: number, query: FindOptionsDto<HandlingUnitDto>) {
		return this.api.post<HandlingUnitDto>(`handlingUnits.getById`, { body: { data: { id, ...query } } });
	}

	// POST @ handling-units/:id?
	save(id: number, body: Partial<HandlingUnitDto>) {
		return this.api.post<HandlingUnitDto>(`handlingUnits.save`, { body: { data: { id, ...body } } });
	}
}
