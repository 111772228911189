/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { PackageUnitDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class PackageUnitDataAccessService {
	private api = inject(ApiService);

	// GET @ packages/units/
	getAll(query: EntityQueryDto<PackageUnitDto>) {
		return this.api.post<PackageUnitDto[]>(`packageUnits.getAll`, { body: { data: query } });
	}

	// GET @ packages/units/:id
	getById(id: number, query: FindOptionsDto<PackageUnitDto>) {
		return this.api.post<PackageUnitDto>(`packageUnits.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<PackageUnitDto>) {
		return this.api.post<number>(`packageUnits.count`, { body: { data: query } });
	}

	// POST @ packages/units/:id/storno
	storno(id: number) {
		return this.api.post<PackageUnitDto>(`packages/units/${id}/storno`, {});
	}

	// POST @ packages/units/:id/print
	printLabel(id: number, body: any) {
		return this.api.post<void>(`packages/units/${id}/print`, { body });
	}

	// POST @ packages/units/print-labels
	printLabels(body: any) {
		return this.api.post<void>(`packages/units/print-labels`, { body });
	}
}
