/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { ApiService } from '@nest/common/frontend/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { PackageTypeDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class PackageTypeDataAccessService {
	private api = inject(ApiService);

	// GET @ packages/types/
	getAll(query: EntityQueryDto<PackageTypeDto>) {
		return this.api.get<PackageTypeDto[]>(`packages/types/`, query);
	}

	// GET @ packages/types/:id
	getById(id: number, query: FindOptionsDto<PackageTypeDto>) {
		return this.api.get<PackageTypeDto>(`packages/types/${id}`, query);
	}

	// POST @ packages/types/:id?
	save(id: number, body: PackageTypeDto) {
		return this.api.post<PackageTypeDto>(`packages/types/${id}?`, { body });
	}
}
