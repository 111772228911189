import { APP_INITIALIZER, inject } from '@angular/core';
import { GpsPathRendererComponent } from '@nest/domain/common/frontend/entity-presentation';
import { PartyDto, PartyEntityName } from '@nest/domain/entities-metadata/dtos';
import { PartyDataAccessService } from '@nest/domain/parties/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

export function providePartyEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				let dataAccess = inject(PartyDataAccessService);

				return () => {
					const paths = autoPathsFor<PartyDto>();

					entityMetadata.registerEntity({ entityName: PartyEntityName, dataAccess, defaultElements: fieldsToElements(paths(['code', 'name'])) });
					entityMetadata.registerEntityPathRenderer(PartyEntityName, paths(['gps']), GpsPathRendererComponent);
				};
			}
		}
	];
}
