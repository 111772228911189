import { APP_INITIALIZER, inject } from '@angular/core';
import { DeliveryItemDataAccessService, PackageUnitDataAccessService } from '@nest/domain/delivery/packages/frontend/data-access';
import { DeliveryItemEntityName, PackageUnitDto, PackageUnitEntityName } from '@nest/domain/entities-metadata/dtos';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

const packageUnitPaths = autoPathsFor<PackageUnitDto>();

export const PackageUnitDefaultElements = fieldsToElements(
	packageUnitPaths([
		'packedAt',
		'parcelNumber',
		'outboundDeliveryOrder.warehouse.name',
		'outboundDeliveryOrder.documentNumber',
		'state',
		'outboundDeliveryOrder.foreignOrderNumber',
		'recipientSnapshot.name',
		'recipientSnapshot.city',
		'variableSymbol',
		'cashOnDeliveryAmount',
		'cashOnDeliveryCurrency'
	])
);

export function provideDeliveryEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				let deliveryItemDataAccess = inject(DeliveryItemDataAccessService);
				let packageUnitDataAccess = inject(PackageUnitDataAccessService);

				return () => {
					entityMetadata.registerEntity({
						entityName: DeliveryItemEntityName,
						dataAccess: deliveryItemDataAccess,
						defaultElements: []
					});

					entityMetadata.registerEntity({
						entityName: PackageUnitEntityName,
						dataAccess: packageUnitDataAccess,
						defaultElements: PackageUnitDefaultElements
					});
				};
			}
		}
	];
}
