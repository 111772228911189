import { APP_INITIALIZER, inject } from '@angular/core';
import {
	HandlingUnitDto,
	HandlingUnitEntityName,
	HandlingUnitMovementDto,
	HandlingUnitMovementEntityName,
	PackageTypeDto,
	PackageTypeEntityName
} from '@nest/domain/entities-metadata/dtos';
import { HandlingUnitDataAccessService, HandlingUnitMovementDataAccessService, PackageTypeDataAccessService } from '@nest/domain/wms/handling-units/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

export function provideHandlingUnitsEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				let handlingUnitMovementDataAccess = inject(HandlingUnitMovementDataAccessService);
				let handlingUnitDataAccess = inject(HandlingUnitDataAccessService);
				let packageTypeDataAccess = inject(PackageTypeDataAccessService);

				return () => {
					entityMetadata.registerEntity({
						entityName: HandlingUnitMovementEntityName,
						dataAccess: handlingUnitMovementDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<HandlingUnitMovementDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: HandlingUnitEntityName,
						dataAccess: handlingUnitDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<HandlingUnitDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: PackageTypeEntityName,
						dataAccess: packageTypeDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<PackageTypeDto>()(['id']))
					});
				};
			}
		}
	];
}
